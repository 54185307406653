export default () => ({
  filterTable(tableBodyId, value, searchColIdx = 1) {
    value = value.toUpperCase().trim()

    for (let tr of document.querySelectorAll(`#${tableBodyId} tr`)) {
      let td = tr.getElementsByTagName('td')[searchColIdx]
      if (!td?.textContent) continue

      tr.hidden = !td.textContent.toUpperCase().includes(value)
    }
  }
})
